import React from 'react'

function Button(props) {
    return (
        <>
            <a onClick={props.onClick} href={props.link} className={`mt-6 w-60 inline-flex items-center justify-center px-12 py-4 text-base font-medium text-center ${props.colour} ${props.textColour} rounded-full border ${props.borderClr} hover:${props.hoverColour} cursor-pointer focus:ring-4 focus:ring-blue-lightPrimary`}>
                {props.text}
            </a>
        </>
    )
}

export default Button