import Button from '../components/Button';
import Heading from '../components/Heading';
import Navbar from '../components/Navbar';

// primary - #240046
// secondary - #FF9E00
// lightPrimary - #B668FF
// lightSecondary - #F8B445

function OptionPage() {
    return (
        <div className="flex flex-col items-start w-screen h-screen bg-blue-primary">
            <div className='flex flex-col items-center justify-center w-full h-full p-12'>
                <div className="flex flex-col items-center w-full h-1/2">
                    <Navbar marginTop={12}/>
                </div>
                <div className="flex flex-col items-center w-full h-full">
                    <h2 className="text-3xl font-sfPro font-normal text-blue-secondary">Welcome to <span className='font-clashDisplay font-bold'>BrainBox</span></h2>
                    <Button text="Login" colour="bg-blue-secondary" textColour="text-blue-primary" hoverColour="bg-blue-lightSecondary" link="/login" borderClr="border-blue-lightSecondary" />
                    <Button text="Sign Up" colour="bg-[#2F025A]" textColour="text-blue-secondary" link="/signup" borderClr="border-blue-lightPrimary" hoverColour="bg-blue-primary" />
                </div>
            </div>
        </div>
    );
}

export default OptionPage;
