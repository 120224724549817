import Heading from '../components/Heading';
import Navbar from '../components/Navbar';

// primary - #240046
// secondary - #FF9E00
// lightPrimary - #B668FF
// lightSecondary - #F8B445

function MainPage() {
    return (
        <div className="flex flex-col items-start w-screen h-screen bg-blue-primary">
            <div className='flex flex-col items-center justify-center w-full h-full p-12'>
                <div className="flex flex-col items-center w-full h-1/2">
                    <Navbar marginTop={12}/>
                </div>
                <div className="flex flex-col items-center w-full h-full">
                    <Heading />
                </div>
            </div>
        </div>
    );
}

export default MainPage;
