import React from 'react'

function Navbar(props) {
    return (
        <nav className="p-3 border-gray-200 rounded">
            <div className="container flex flex-wrap items-center justify-center mx-auto">
                <a href="/" className="flex items-center">
                    <span className={`self-center text-5xl ${props.marginTop} font-clashDisplay whitespace-nowrap font-bold text-blue-secondary text-center`}>BrainBox</span>
                </a>
            </div>
        </nav>
    )
}

export default Navbar