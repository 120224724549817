import './App.css';
import Heading from './components/Heading';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import MainPage from './pages/MainPage';
import OptionPage from './pages/OptionPage';
import SignUp from './pages/SignUp';
import Chat from './pages/Chat';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// primary - #240046
// secondary - #FF9E00
// lightPrimary - #B668FF
// lightSecondary - #F8B445

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/" exact component={MainPage} />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/option" component={OptionPage} />
          <Route path="/chat" component={Chat} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
