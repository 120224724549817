import React from 'react'
import { Link } from 'react-router-dom'

function Heading() {
    return (
        <>
            <h1 className="mb-4 text-4xl font-extrabold font-sfPro leading-none tracking-tight text-center text-blue-secondary md:text-5xl lg:text-6xl">Get Instant Answers to <br/>Your Questions with BrainBox</h1>
            <p className="mb-6 text-lg font-normal text-blue-secondary font-sfPro lg:text-xl sm:px-16 xl:px-48 w-full md:w-3/4 text-center">Whether you're a student looking for help with homework, a professional searching for industry insights, or simply curious about the world around you, BrainBox is the perfect tool for you. Try it today and experience the power of instant knowledge at your fingertips!</p>
            <a href="/option" className="mt-6 w-60 inline-flex items-center justify-center px-12 py-4 text-base font-medium text-center text-blue-primary bg-blue-secondary rounded-full border-2 border-blue-lightSecondary hover:bg-blue-lightSecondary focus:ring-4 focus:ring-blue-lightPrimary">
                Start Now
                <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
        </>
    )
}

export default Heading